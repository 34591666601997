/** @jsx jsx */
import Widget from "gatsby-theme-pages/src/components/Widget";
import type { Props } from "gatsby-theme-pages/src/components/Widget/Section";
import WidgetWrapper from "gatsby-theme-pages/src/components/WidgetWrapper";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import Wrapper from "./Wrapper";

const SectionWidget: React.FC<Props> = ({
  data,
  isNested,
  isFirst,
  isLast,
  widget,
  prevWidget,
  nextWidget,
}) => {
  const shape = widget.sectionShape;
  const widgets = shape?.widgets;

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={shape?.wrapperSize || "STANDARD"}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
      contained={false}
    >
      <Wrapper
        wrapperSize={shape?.wrapperSize || "STANDARD"}
        containerSize={shape?.containerSize || "STANDARD"}
        backgroundColor={shape?.backgroundColor || "transparent"}
        border={shape?.border || false}
      >
        {widgets?.map((nestedWidget, nestedWidgetIndex) => {
          if (!nestedWidget) {
            return null;
          }

          return (
            <Widget
              key={nestedWidget.id}
              widget={nestedWidget}
              data={data}
              isFirst={nestedWidgetIndex === 0}
              isLast={nestedWidgetIndex === (widgets?.length || 0) - 1}
              isNested={true}
              shouldBleed={shape?.containerSize === "FULL"}
              nextWidget={widgets[nestedWidgetIndex + 1]}
              containerSize={shape?.containerSize || "STANDARD"}
            />
          );
        })}
      </Wrapper>
    </WidgetWrapper>
  );
};

export default memo(SectionWidget);
